/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from 'src/components/GTM';

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="cs" />
          <body />

          <meta charSet="utf-8" />
          <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
          <link rel="icon" href="/favicon.ico" type="image/x-icon" />
          <script src="/themes/neutrogenacz/js/jquery-3.5.1.js" />
          <script src="/themes/neutrogenacz/js/jquery-migrate-3.3.0.js" />
          <script type="text/javascript" src="/themes/neutrogenacz/js/jquery-migrate-1.4.1.js" />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/fancybox/jquery.mousewheel-3.0.4.pack.js"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/fancybox/jquery.fancybox-1.3.4.pack.js"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/fancybox/jquery.fancybox-init.js"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/themes/neutrogenacz/fancybox/jquery.fancybox-1.3.4.css"
            media="screen"
          />
          <link
            rel="stylesheet"
            href="/themes/neutrogenacz/projekktor/theme/proj.css"
            type="text/css"
            media="screen"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/projekktor/projekktor-1.2.19r188.min.js"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/projekktor/projekktor-init.js"
          />
          <link
            href="/themes/neutrogenacz/css/cookie.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/neutrogenacz/css/style.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/themes/neutrogenacz/css/demo.css"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/js/core.js"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/js/products.js"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/js/customInput.jquery.js"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/js/customInput_init.js"
          />
          <script
            type="text/javascript"
            innerHTML='
<!--//--><![CDATA[//><!--
function OptanonWrapper() { Optanon.InsertHtml(&apos;<div class="fb"><div style="width:250px; height:auto; float:left;"><iframe src="//www.facebook.com/plugins/like.php?locale=cs_CZ&href=https%3A%2F%2Fwww.neutrogena-kosmetika.cz%2Fproduct%2F62&amp;send=false&amp;layout=button_count&amp;width=200&amp;show_faces=false&amp;font&amp;colorscheme=light&amp;action=like&amp;height=21&amp;"scrolling="no" frameborder="0"style="border:none; overflow:hidden; width:200px; height:21px;"allowTransparency="true"></iframe></div></div>&apos;, &apos;fb_23&apos;, null, null, &apos;4&apos;); }
//--><!]]>
'
          />
          <script
            innerHTML='
$(document).ready(function(){
var str = $("p", "h2" ).html();
$( "h2" ).html( str );
});
'
          />
          <title>NEUTROGENA®</title>

        </Helmet>
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
